<template>

	<!-- 凭证添加界面 -->
	<el-dialog 
		:title="$dict('添加凭证')" top="2vh" 
		append-to-body custom-class="el-dialog-s" 
		width="500px" :visible.sync="is_show_in_Page" 
	>
		<div class="upl">
			<div class="img_area" @click="choose_img">
				<img v-if="src" :src="src" class="show">
				<i v-else class="el-icon-plus avatar-uploader-icon icon"></i>
			</div>
			<textarea :placeholder="$dict('凭证备注')" class="mark_area" v-model="mark"></textarea>
			<div class="btn_area">
				<el-button type="primary" @click="sub">{{ $dict('提交') }}</el-button>
			</div>
		</div>
	</el-dialog>
</template>
<script>
	export default {
		props:{
			business_container_id:String,//集装箱业务编号
			ctyard_reserved_id:String,//提箱预约编号
			factory_reserved_id:String,//提箱预约编号
			port_reserved_id:String,//提箱预约编号
			truck_task_id:String,//提箱预约编号
			is_show:Number,//是否显示
		},
		data() {
			return {
				is_show_in_Page:false,
				src:'',
				mark:''
			}
		},
		watch:{
			is_show(new_data){
				if(new_data){
					this.is_show_in_Page=true;
					this.src="";
					this.mark="";
				}else{
					this.is_show_in_Page=false;
				}
			},
		},
		methods: {

			choose_img(){//选择图片

				//选择图片
				this.$my.cos.choose({
					success: (res) => {
						this.src=res.src
					}
				});
			},

			//提交
			sub(){

				//上传
				this.$my.cos.upl({
					bucket: 'businesscontainervoucher',
					file_path: this.src,
					callback: (data) => {

						//上传
						this.$my.net.req({
							loading:true,
							data:{
								mod:'business',
								ctr:'business_voucher_add_by_admin',
								business_container_id:this.business_container_id,
								ctyard_reserved_id:this.ctyard_reserved_id,
								factory_reserved_id:this.factory_reserved_id,
								port_reserved_id:this.port_reserved_id,
								truck_task_id:this.truck_task_id,
								img_key:data.key,
								mark:this.mark
							},
							callback:(data)=>{
								this.$my.other.msg({
									type:'success',
									str:"操作成功"
								});
								this.is_show_in_page=false;
								this.$emit('added')
							}
						});
					}
				});
			},
		}
	}
</script>

<style lang="scss" scoped>

	.upl{
		.img_area{
			border:1px solid #ccc;
			cursor: pointer;
			padding: 5px;
			.show{
				margin: auto;
				display: block;
				max-width: 100%;
				max-height: 100%;
			}
			.icon{
				text-align: center;
				display: block;
				font-size: 100px;
				height: 500px;
				line-height: 500px;
			}
		}
		.mark_area{
			border: 1px solid #ccc;
			padding: 5px;
			width: 448px;
			margin-top: 10px;
			height: 100px;
			outline: none;
			font-size: 16px;
		}
		.mark_area::placeholder {
			font-size: 20px;
			line-height: 100px;
			text-align: center; 
		}
		.btn_area{
			text-align: center;
			margin-top: 15px;
		}
	}

	.el-dialog-s{
		z-index: 11;
	}
</style>