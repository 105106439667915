<template>

	<!-- 凭证界面 -->
	<el-dialog :title="$dict('凭证列表')" top="1vh" width="90%" :visible.sync="is_show_in_Page">
		<div class="voucher">
			<div class="header">
				<el-button type="primary" size="small" @click="add_view_open">{{ $dict('上传') }}</el-button>
			</div>
			<div class="list" v-if="list.length>0">
				<div class="item" v-for="(item,index) in list" :key="index">
					<div class="del_btn" @click="del(item)">×</div>
					<img class="img" :src="item.src">
					<div class="text">{{item.mark}}</div>
				</div>
			</div>
			<div class="list_none" v-else>{{ $dict('无数据') }}</div>
		</div>
		
		<!-- 添加弹出层 -->
		<add 
			:is_show="add_view.is_show" 
			:business_container_id="business_container_id" 
			:ctyard_reserved_id="ctyard_reserved_id" 
			:factory_reserved_id="factory_reserved_id" 
			:port_reserved_id="port_reserved_id" 
			:truck_task_id="truck_task_id" 
			@added="add_view_close" 
		></add>

	</el-dialog>
</template>
<script>
	import add from "./add.vue";
	export default {
		components:{
			add,
		},
		props:{
			business_container_id:String,//集装箱业务编号
			ctyard_reserved_id:String,//提箱预约编号
			factory_reserved_id:String,//提箱预约编号
			port_reserved_id:String,//提箱预约编号
			truck_task_id:String,//提箱预约编号
			is_show:Number,//是否显示
		},
		data() {
			return {

				//列表是否显示
				is_show_in_Page:false,

				//凭证列表
				list:[],

				//上传界面
				add_view:{
					is_show:0,
				}
			}
		},
		watch:{
			is_show(new_data){
				if(new_data){
					this.is_show_in_Page=true;
					this.list=[];
					this.load_data()
				}
			},
		},
		methods: {

			//集装箱业务凭证上传
			add_view_open(){//打开
				this.add_view.is_show++
			},
			add_view_close(){//关闭
				this.add_view.is_show=0
				this.load_data()
			},

			//删除
			del(item){

				//询问
				this.$my.other.confirm({
					content: "点击'确定'删除凭证",
					confirm: ()=>{
						
						//执行删除
						this.$my.net.req({
							loading:true,
							data:{
								mod:'business',
								ctr:'business_voucher_del',
								business_voucher_id:item.id,
							},
							callback:(data)=>{
								this.$my.other.msg({
									type:'success',
									str:'操作成功'
								});
								this.load_data()
							}
						});
					}
				});
			},

			//读取数据
			load_data(){

				//读取数据
				this.$my.net.req({
					data:{
						mod:'business',
						ctr:'business_voucher_list',
						business_container_id:this.business_container_id,
						ctyard_reserved_id:this.ctyard_reserved_id,
						factory_reserved_id:this.factory_reserved_id,
						port_reserved_id:this.port_reserved_id,
						truck_task_id:this.truck_task_id,
					},
					callback:(data)=>{

						//构造数据
						for(var item of data.list){

							//时间
							item.create_time_text=this.$my.other.totime(item.create_time);
							item.update_time_text=this.$my.other.totime(item.update_time);

							//src
							item.src=this.$my.cos.make_src('businesscontainervoucher', item.img_key);
						}

						this.list=data.list
					}
				});
			},
		}
	}
</script>

<style lang="scss" scoped>

	.voucher{
		margin-top: -10px;
		.header{
			padding-bottom: 20px;
			border-bottom: 1px solid #ccc;
		}
		.list_none{
			height:300px;
			line-height:300px;
			text-align:center;
			font-size: 18px;
		}
		.list{
			display: flex;
			flex-wrap: wrap;
			padding-bottom: 30px;
			.item{
				margin: 15px 0.4% 0 0.4%;
				width: 19%;
				height: 19vw;
				position: relative;
				border: 1px solid #ccc;
				border-radius: 5px;
				.del_btn{
					font-size: 20px;
					width: 40px;
					height: 40px;
					line-height: 40px;
					text-align: center;
					border-radius: 20px;
					color: #fff;
					background-color: #000;
					opacity: 0.5;
					position: absolute;
					right: -10px;
					top: -10px;
					cursor: pointer;
				}
				.img{
					max-width: 95%;
					max-height: calc( 100% - 20px - 1vw);
					display: block;
					margin: 0.3vw auto 0 auto;
				}
				.text{
					position: absolute;
					bottom: 0;
					width: 100%;
					height: 40px;
					line-height: 40px;
					text-align: center;
				}
			}
		}
	}
</style>