<template>
	<el-dialog :title="$dict(title_in_page)" top="1vh" width="600px" append-to-body :visible.sync="is_show_in_page">
		<easyPrint tableShow ref="easyPrint">
			<div style="display: flex;justify-content: space-between;">
				<div class="code" id="qrcode" ref="qrcode"></div>
				<div style="width: 100%;">
					<slot></slot>
				</div>
			</div>
		</easyPrint>
		<el-button style="position: absolute;top:16px;left: 110px;" @click="print()" type="primary" size="small">{{ $dict('打印') }}</el-button>
	</el-dialog>
</template>

<script>
	import QRCode from 'qrcodejs2'
	import easyPrint from 'vue-easy-print'
	export default {
		props:{
			is_show:Number,
			title:String,
			str:String,
		},
		components:{
			easyPrint
		},
		data() {
			return {
				is_show_in_page:false,
				title_in_page:'二维码',
			}
		},
		watch:{
			is_show(new_data){
				if(new_data){

					this.is_show_in_page=true;
					this.init()

				}else{

					this.is_show_in_page=false;
				}
			}
		},
		methods: {

			//打印
			print(){
				this.$refs.easyPrint.print()
			},

			//初始化
			init(){
				if(this.title){
					this.title_in_page=this.title
				}
				setTimeout(()=>{
					document.getElementById("qrcode").innerHTML=""
					new QRCode(this.$refs.qrcode, {
						text: this.str,
						width: 220,
						height: 220,
						colorDark: "#000000",
						colorLight: "#ffffff",
						correctLevel: QRCode.CorrectLevel.H,
					});
				})
			},
		}
	}
</script>