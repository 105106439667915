<template>
	<div class="page">

		<!-- 搜索条件表单 -->
		<el-form class="ser_form" size="small"  label-width="160px">
			<el-form-item class="el_form_item" :label="$dict('堆场选择')">
				<el-input class="el_input" v-model="form.ctyard_name" :disabled="true">
					<el-button slot="append" @click="ctyard_choose_open">{{ $dict('选择') }}</el-button>
				</el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="booking">
				<el-input class="el_input" v-model="form.booking_num" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" :label="$dict('箱型选择')">
				<el-select class="el_input" v-model="form.container_type_name" clearable>
					<el-option :label="$dict('不限')" value=""></el-option>
					<el-option v-for="(item,index) in container_type_list" :key="index" :label="item.name" :value="item.name"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="el_form_item" :label="$dict('箱号')">
				<el-input class="el_input" v-model="form.container_number" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" :label="$dict('封号')">
				<el-input class="el_input" v-model="form.container_seal_number" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" :label="$dict('日期起点')">
				<el-date-picker class="el_input" v-model="form.reserved_date_start_obj" type="date" align="center"></el-date-picker>
			</el-form-item>
			<el-form-item class="el_form_item" :label="$dict('日期止点')">
				<el-date-picker class="el_input" v-model="form.reserved_date_end_obj" type="date" align="center"></el-date-picker>
			</el-form-item>
			<el-form-item class="el_form_item" :label="$dict('时段起点')">
				<el-time-select 
					class="el_input" 
					v-model="form.reserved_start_time_text" 
					:picker-options="start_time_select_option" 
				></el-time-select>
			</el-form-item>
			<el-form-item class="el_form_item" :label="$dict('时段止点')">
				<el-time-select 
					class="el_input" 
					v-model="form.reserved_end_time_text" 
					:picker-options="end_time_select_option" 
				></el-time-select>
			</el-form-item>
			<el-form-item class="el_form_item" :label="$dict('备注')">
				<el-input class="el_input" v-model="form.mark" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" :label="$dict('状态')">
				<el-select class="el_input" v-model="form.status" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="未预约" value="1"></el-option>
					<el-option label="预约发起" value="2"></el-option>
					<el-option label="预约确认" value="3"></el-option>
					<el-option label="预约失败" value="4"></el-option>
					<el-option label="车辆到达" value="5"></el-option>
					<el-option label="开始装箱" value="6"></el-option>
					<el-option label="装箱完成" value="7"></el-option>
					<el-option label="验箱开始" value="8"></el-option>
					<el-option label="验箱完成" value="9"></el-option>
					<el-option label="已上传箱封号" value="10"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label-width="0">
				<el-button type="primary" @click="page_ser">查询</el-button>
				<el-button type="success" @click="add_view_open">添加</el-button>
			</el-form-item>
		</el-form>

		<!-- 表格 -->
		<div class="tab_height">
			<el-table :data="list.data" :border="true" :stripe="true" size="small" height="100%" v-loading="list.loading">
				<el-table-column :label="$dict('添加/修改时间')" width="145">
					<template slot-scope="scope">
						<div>{{scope.row.create_time_text}}</div>
						<div>{{scope.row.update_time_text}}</div>
					</template>
				</el-table-column>
				<el-table-column :label="$dict('堆场名称/地址')">
					<template slot-scope="scope">
						<div>{{scope.row.ctyard_info.name}}</div>
						<div>{{scope.row.ctyard_info.addr}}</div>
					</template>
				</el-table-column>
				<el-table-column :label="$dict('booking/箱型/日期/时段')" width="240">
					<template slot-scope="scope">
						<div>{{ $dict('箱型') }}:{{scope.row.container_type_name}} / booking:{{scope.row.booking_num}}</div>
						<div>{{ $dict('日期') }}:{{scope.row.reserved_date_full_text}}</div>
					</template>
				</el-table-column>
				<el-table-column :label="$dict('集装箱数据')" width="170">
					<template slot-scope="scope">
						<div>{{ $dict('箱号') }}:{{scope.row.container_number_text}}</div>
						<div>{{ $dict('封号') }}:{{scope.row.container_seal_number_text}}</div>
						<div>{{ $dict('箱重') }}:{{scope.row.container_weight}}{{ $dict('吨') }}</div>
					</template>
				</el-table-column>
				<el-table-column :label="$dict('司机/货车')" width="230">
					<template slot-scope="scope">
						<div>{{ $dict('司机') }}:{{scope.row.driver_user_info.name}} / {{scope.row.driver_user_info.tel}}</div>
						<div>{{ $dict('货车') }}:{{scope.row.truck_van_plate_num_text}} / {{ $dict('挂车') }}:{{scope.row.truck_trailer_plate_num_text}}</div>
					</template>
				</el-table-column>
				<el-table-column :label="$dict('状态/时效')" width="145">
					<template slot-scope="scope">
						<div>{{ $dict('状态') }}:{{scope.row.status_text}}</div>
						<div>{{scope.row.status_time_text}}</div>
					</template>
				</el-table-column>
				<el-table-column :label="$dict('支付状态')" width="145">
					<template slot-scope="scope">
						<div>{{scope.row.pay_status_text}}</div>
						<div>{{scope.row.payed_time_text}}</div>
					</template>
				</el-table-column>
				<el-table-column :label="$dict('提箱费用')" width="120">
					<template slot-scope="scope">
						<div v-for="(fee_item,fee_index) in scope.row.fee_detail">{{fee_item.name}}:{{fee_item.much}}</div>
					</template>
				</el-table-column>
				<el-table-column :label="$dict('开票相关')" width="150">
					<template slot-scope="scope">
						<div>{{ $dict('对象') }}:{{scope.row.invoice_for_text}}</div>
						<div>{{ $dict('点数') }}:{{scope.row.tax_rate}}% / {{scope.row.tax_type_text}}</div>
						<div>{{ $dict('税额') }}:{{scope.row.tax_much}}</div>
					</template>
				</el-table-column>

				<!-- 操作行 -->
				<el-table-column :label="$dict('操作')" width="300">
					<template slot-scope="scope">
						<div v-if="scope.row.status==3">
							<el-button @click="arrived(scope.row)" size="mini" type="text">{{ $dict('确认到达') }}</el-button>
						</div>
						<div v-if="scope.row.status==5">
							<el-button @click="load_completed(scope.row)" size="mini" type="text">{{ $dict('装箱完成') }}</el-button>
							<el-button @click="check_completed(scope.row)" size="mini" type="text">{{ $dict('验箱完成') }}</el-button>
							<el-button @click="number_upload_view_open(scope.row)" size="mini" type="text">{{ $dict('上传箱封号') }}</el-button>
						</div>
						<div v-if="scope.row.status==7">
							<el-button @click="check_completed(scope.row)" size="mini" type="text">{{ $dict('验箱完成') }}</el-button>
							<el-button @click="number_upload_view_open(scope.row)" size="mini" type="text">{{ $dict('上传箱封号') }}</el-button>
						</div>
						<div v-if="scope.row.status==9">
							<el-button @click="number_upload_view_open(scope.row)" size="mini" type="text">{{ $dict('上传箱封号') }}</el-button>
						</div>
						<div>
							<el-button @click="qrcode_view_show(scope.row)" size="mini" type="text" >{{ $dict('二维码') }}</el-button>
							<el-button @click="voucher_view_show(scope.row)" size="mini" type="text" >{{ $dict('凭证列表') }}</el-button>
							<el-button v-if="scope.row.invoice_for==3" @click="del(scope.row)" size="mini" type="text" style="color:red">{{ $dict('删除预约') }}</el-button>
						</div>
					</template>
				</el-table-column>
			</el-table>
		</div>

		<!-- 分页 -->
		<el-pagination 
			class="margin-top-xl" 
			@size-change="page_size_change" 
			@current-change="page_current_change" 
			:current-page="page.p" 
			:page-sizes="[10, 20, 30, 40]" 
			:page-size="page.num" 
			:total="list.total*1" 
			layout="total, sizes,prev, pager, next, jumper" 
		></el-pagination>

		<!-- 添加弹出层 -->
		<add 
			:is_show="add_view.is_show" 
			@added="add_view_close" 
		></add>

		<!-- 二维码弹出层 -->
		<qrcode :is_show="qrcode.is_show" :str="qrcode.str">
			<el-form size="mini" label-width="160px">
				<el-form-item :label="$dict('堆场')">
					<div>{{ qrcode.ctyard_info.name }}</div>
				</el-form-item>
				<el-form-item :label="$dict('日期')">
					<div>{{ qrcode.reserved_date_full_text }}</div>
				</el-form-item>
				<el-form-item :label="$dict('箱型')">
					<div>{{ qrcode.container_type_name }}</div>
				</el-form-item>
				<el-form-item :label="$dict('车辆')">
					<div>{{ qrcode.truck_van_plate_num_text }}/{{ qrcode.truck_trailer_plate_num_text }}</div>
				</el-form-item>
				<el-form-item :label="$dict('付款')">
					<div>{{ qrcode.pay_status_text }}</div>
				</el-form-item>
			</el-form>
		</qrcode>

		<!-- 添加弹出层 -->
		<numberUploadView 
			:is_show="number_upload_view.is_show" 
			:data="number_upload_view.data" 
			@upld="number_upload_view_close" 
		></numberUploadView>
		
		<!-- 凭证界面 -->
		<voucher 
			:business_container_id="voucher_view.business_container_id" 
			:ctyard_reserved_id="voucher_view.ctyard_reserved_id" 
			:is_show="voucher_view.is_show" 
		></voucher>

		<!-- 堆场选择弹出层 -->
		<ctyardChoose 
			:is_show="ctyard_choose.is_show" 
			@choosed="ctyard_choosed" 
		></ctyardChoose>

	</div>
</template>

<script>
	import add from './add.vue'
	import qrcode from '@/components/qrcode.vue'
	import numberUploadView from './number_upload_view.vue'
	import voucher from '../../business/business_voucher/list.vue'
	import ctyardChoose from '../../ctyard/ctyard_list/choose.vue'
	export default {
		components:{
			add,
			qrcode,
			numberUploadView,
			voucher,
			ctyardChoose,
		},
		data() {
			return {

				//堆场列表
				container_type_list:[],

				//时间选择参数
				start_time_select_option:{
					start: '05:00',
					step: '00:30',
					end: '22:00'
				},
				end_time_select_option:{
					start: '05:30',
					step: '00:30',
					end: '22:00'
				},

				//搜索条件
				form: {
					ctyard_id:'',
					ctyard_name:'',
					booking_num:'',
					container_type_name:'',
					container_number:'',
					container_seal_number:'',
					reserved_date_start_obj:'',
					reserved_date_end_obj:'',
					reserved_start_time_text:'',
					reserved_end_time_text:'',
					status:'',
					mark:'',
				},

				//表格数据
				list: {
					loading: false,//loading特效
					data: [],//列表数据
					total: 0,//列表总记录数
				},

				//页码信息
				page: {
					need_num:1,
					num: 10,
					p: 1,
				},

				//添加
				add_view:{
					is_show:0,
				},

				//上传箱封号界面
				number_upload_view:{
					data:{},
					is_show:0,
				},

				//凭证相关
				voucher_view:{
					is_show:0,
					business_container_id:'',
					ctyard_reserved_id:'',
				},
				
				//堆场选择
				ctyard_choose:{
					is_show:0,
				},

				//二维码
				qrcode:{
					is_show:0,
					ctyard_info:{},
					str:''
				},
			}
		},
		created() {

			//预约日期,默认当日
			let now_time=new Date()
			let now_hour=now_time.getHours()
			this.form.reserved_date_start_obj=now_time

			//预约时间,默认当前时间
			this.form.reserved_start_time_text=`${now_hour<10?'0'+now_hour:now_hour}:00`

			//取出参数
			let query=this.$route.query;
			if(query.booking_num){
				this.form.booking_num=query.booking_num
			}

			//读取页面数据
			this.page_ser()
		},
		methods: {

			//二维码
			qrcode_view_show(item){//打开
				this.qrcode.str=JSON.stringify({
					type:'ctyard_reserved',
					data:{ctyard_reserved_id:item.ctyard_reserved_id},
				})
				this.qrcode={
					...this.qrcode,
					...item
				}
				this.qrcode.is_show++
			},

			//堆场选择
			ctyard_choose_open(){//打开
				this.ctyard_choose.is_show++
			},
			ctyard_choosed(obj){//关闭
				this.ctyard_choose.is_show=0
				this.form.ctyard_id=obj.ctyard_id
				this.form.ctyard_name=obj.name
			},

			//凭证界面
			voucher_view_show(item){//打开
				this.voucher_view.is_show++;
				this.voucher_view.business_container_id=item.business_container_id
				this.voucher_view.ctyard_reserved_id=item.ctyard_reserved_id
			},

			//上传箱封号界面
			number_upload_view_open(item){//打开
				this.number_upload_view.data=item
				this.number_upload_view.is_show++
			},
			number_upload_view_close(){//关闭
				this.number_upload_view.is_show=0
				this.page_ser()
			},

			//验箱完成
			check_completed(item){

				//询问
				this.$my.other.confirm({
					content:"点击'确定'验箱完成",
					confirm:()=>{
						this.$my.net.req({
							data:{
								mod:'ctyard',
								ctr:'ctyard_reserved_check_completed_by_truck_carrier',
								ctyard_reserved_id:item.ctyard_reserved_id,
							},
							callback:(data)=>{
								this.$my.other.msg({
									type:'success',
									str:'操作成功'
								});
								this.page_ser()
							},
						});
					}
				});
			},

			//装箱完成
			load_completed(item){

				//询问
				this.$my.other.confirm({
					content:"点击'确定'装箱完成",
					confirm:()=>{
						this.$my.net.req({
							data:{
								mod:'ctyard',
								ctr:'ctyard_reserved_load_completed_by_truck_carrier',
								ctyard_reserved_id:item.ctyard_reserved_id,
							},
							callback:(data)=>{
								this.$my.other.msg({
									type:'success',
									str:'操作成功'
								});
								this.page_ser()
							},
						});
					}
				});
			},

			//确认到达
			arrived(item){

				//询问
				this.$my.other.confirm({
					content:"点击'确定'确认到达",
					confirm:()=>{
						this.$my.net.req({
							data:{
								mod:'ctyard',
								ctr:'ctyard_reserved_arrived_by_truck_carrier',
								ctyard_reserved_id:item.ctyard_reserved_id,
							},
							callback:(data)=>{
								this.$my.other.msg({
									type:'success',
									str:'操作成功'
								});
								this.page_ser()
							},
						});
					}
				});
			},

			// //驳回
			// refuse(item){

			// 	//询问
			// 	this.$my.other.confirm({
			// 		content:"点击'确定'确认到达",
			// 		confirm:()=>{
			// 			this.$my.net.req({
			// 				data:{
			// 					mod:'ctyard',
			// 					ctr:'ctyard_reserved_refuse_by_truck_carrier',
			// 					ctyard_reserved_id:item.id,
			// 				},
			// 				callback:(data)=>{
			// 					this.$my.other.msg({
			// 						type:'success',
			// 						str:'操作成功'
			// 					});
			// 					this.page_ser()
			// 				},
			// 			});
			// 		}
			// 	});
			// },

			// //预约确认
			// confirm(item){

			// 	//询问
			// 	this.$my.other.confirm({
			// 		content:"点击'确定'确认到达",
			// 		confirm:()=>{
			// 			this.$my.net.req({
			// 				data:{
			// 					mod:'ctyard',
			// 					ctr:'ctyard_reserved_confirm_by_truck_carrier',
			// 					ctyard_reserved_id:item.id,
			// 				},
			// 				callback:(data)=>{
			// 					this.$my.other.msg({
			// 						type:'success',
			// 						str:'操作成功'
			// 					});
			// 					this.page_ser()
			// 				},
			// 			});
			// 		}
			// 	});
			// },

			//删除
			del(item){

				//询问
				this.$my.other.confirm({
					content:"点击确定删除此项",
					confirm:()=>{
						this.$my.net.req({
							data:{
								mod:'ctyard',
								ctr:'ctyard_reserved_del_by_truck_carrier',
								ctyard_reserved_id:item.ctyard_reserved_id,
							},
							callback:(data)=>{
								this.$my.other.msg({
									type:'success',
									str:'操作成功'
								});
								this.page_ser()
							},
						});
					}
				});
			},

			//添加
			add_view_open(){//打开
				this.add_view.is_show++
			},
			add_view_close(item){//关闭
				this.add_view.is_show=0
				this.page_ser()
			},

			//搜索
			page_ser(){
				this.page.p=1
				this.get_page_data()
			},

			//页宽变化
			page_size_change(num){
				this.page.num=num
				this.get_page_data()
			},

			//页码变化
			page_current_change(p){
				this.page.p=p
				this.get_page_data()
			},

			//读取用户组数据
			get_page_data(){

				//加载中...
				if(this.list.loading){
					return;
				}

				//置为加载中
				this.list.loading=true;

				//读取数据
				this.$my.net.req({
					data:{
						mod:'ctyard',
						ctr:'ctyard_reserved_list_by_truck_carrier',
						is_get_business_container_info:1,
						is_get_forwarder_info:1,
						is_get_factory_info:1,
						is_get_driver_user_info:1,
						is_get_ctyard_info:1,
						...this.form,
						...this.page
					},
					callback:(data)=>{

						//加载完毕
						this.list.loading=false

						//总数
						this.list.total=data.max

						//预处理
						for(let item of data.list){

							//取出各种info
							item.driver_user_info=item.driver_user_info[0];
							item.ctyard_info=item.ctyard_info[0];
							item.business_container_info=item.business_container_info[0];
							item.business_container_info.forwarder_info=item.business_container_info.forwarder_info[0]?item.business_container_info.forwarder_info[0]:{}
							item.business_container_info.factory_info=item.business_container_info.factory_info[0]?item.business_container_info.factory_info[0]:{}
							item.forwarder_info=item.business_container_info.forwarder_info
							item.factory_info=item.business_container_info.factory_info

							//创建/修改时间
							item.create_time_text=this.$my.other.totime(item.create_time);
							item.update_time_text=this.$my.other.totime(item.update_time);

							//状态
							switch(item.status){
								case '1':
									item.status_text="未预约";
									item.status_time_text='无';
									break;

								case '2':
									item.status_text="预约发起";
									item.status_time_text=this.$my.other.totime(item.reserved_create_time);
									break;

								case '3':
									item.status_text="预约确认";
									item.status_time_text=this.$my.other.totime(item.reserved_confirm_time);
									break;

								case '4':
									item.status_text="预约失败";
									item.status_time_text=this.$my.other.totime(item.reserved_failed_time);
									break;

								case '5':
									item.status_text="车辆到达";
									item.status_time_text=this.$my.other.totime(item.truck_arrived_time);
									break;

								case '6':
									item.status_text="开始装箱";
									item.status_time_text=this.$my.other.totime(item.load_start_time);
									break;

								case '7':
									item.status_text="装箱完成";
									item.status_time_text=this.$my.other.totime(item.load_completed_time);
									break;

								case '8':
									item.status_text="验箱开始";
									item.status_time_text=this.$my.other.totime(item.check_start_time);
									break;

								case '9':
									item.status_text="验箱完成";
									item.status_time_text=this.$my.other.totime(item.check_completed_time);
									break;

								case '10':
									item.status_text="已上传箱封号";
									item.status_time_text=this.$my.other.totime(item.number_upload_time);
									break;
							}

							//支付状态
							switch(item.pay_status){
								case '1':
									item.pay_status_text="未支付";
									item.payed_time_text='';
									break;

								case '2':
									item.pay_status_text="支付中";
									item.payed_time_text='';
									break;

								case '3':
									item.pay_status_text="支付成功";
									item.payed_time_text=this.$my.other.totime(item.payed_time);
									break;

								case '4':
									item.status_text="支付失败";
									item.payed_time_text=this.$my.other.totime(item.payed_time);
									break;
							}

							//开票对象(1:工厂,2:货代,3:车队)
							switch(item.invoice_for){
								case '1':item.invoice_for_text=`${item.factory_info.name}(工厂)`;break;
								case '2':item.invoice_for_text=`${item.forwarder_info.name}(货代)`;break;
								case '3':item.invoice_for_text=`(车队)`;break;
							}

							//箱号
							item.container_number_text=item.container_number?item.container_number:'无';

							//封号
							item.container_seal_number_text=item.container_seal_number?item.container_seal_number:'无';

							//货车车牌号
							item.truck_van_plate_num_text=item.truck_van_plate_num?item.truck_van_plate_num:'无';

							//挂车车牌号
							item.truck_trailer_plate_num_text=item.truck_trailer_plate_num?item.truck_trailer_plate_num:'无';

							//预约日期(当日0时时间戳)
							item.reserved_date_text=this.$my.other.todate(item.reserved_date)

							//预约时段起点(相对于当天0点的秒数)
							let reserved_start_time_hour=`${parseInt(item.reserved_start_time / 3600)}`
							let reserved_start_time_min=`${parseInt(item.reserved_start_time % 60)}`
							if(reserved_start_time_hour<10)reserved_start_time_hour=`0${reserved_start_time_hour}`
							if(reserved_start_time_min<10)reserved_start_time_min=`0${reserved_start_time_min}`
							item.reserved_start_time_text=`${reserved_start_time_hour}:${reserved_start_time_min}`

							//预约时段止点(相对于当天0点的秒数)
							let reserved_end_time_hour=`${parseInt(item.reserved_end_time / 3600)}`
							let reserved_end_time_min=`${parseInt(item.reserved_end_time % 60)}`
							if(reserved_end_time_hour<10)reserved_end_time_hour=`0${reserved_end_time_hour}`
							if(reserved_end_time_min<10)reserved_end_time_min=`0${reserved_end_time_min}`
							item.reserved_end_time_text=`${reserved_end_time_hour}:${reserved_end_time_min}`

							//预约日期时段完整字符串
							item.reserved_date_full_text=`${item.reserved_date_text}/${item.reserved_start_time_text}~${item.reserved_end_time_text}`

							//费用详情
							item.fee_detail=JSON.parse(item.fee_detail);

							//计税方式(1:除法,2:乘法)
							switch(item.tax_type){
								case '1':item.tax_type_text="除法";break;
								case '2':item.tax_type_text="乘法";break;
							}
						}

						//渲染
						this.list.data=data.list
					}
				})
			},
		}
	}
</script>

<style lang="scss" scoped>

	//修改/添加表单的额外权限菜单部分
	.menu_list{
		background-color:#eee;
		height: 500px;
		overflow: auto;
		padding: 0 20px;
		margin-top: 10px;
		width: 90%;
		.children_list{
			margin-left: 20px;
		}
	}

	//其他
	.page{
		background-color: #fff;
		padding: 20px;
		border-radius: 10px;
		height: calc(100% - 40px);
		overflow: auto;
	}
	.ser_form{
		display: flex;
		align-items: flex-end;
		flex-wrap: wrap;
	}
	.tab_height{
		height:calc(100% - 190px);
	}
	.el_form_item{
		width: 25%;
	}
	.el_input{
		width:94%
	}
	.btn_left{
		margin: 0;
	}
</style>